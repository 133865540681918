var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"card-size"},[_c('v-card-title',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$tc('label.estrutura_usuario', 2)))])]),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","align-self":"center"}},[_c('v-row',[(_vm.canEdit && _vm.selected.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.dialogConfirmarInativacao()}}},on),[_c('v-icon',[_vm._v("block")])],1)]}}],null,false,4240950027)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.ativar_inativa')))])]):_vm._e()],1)],1)],1),_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.estruturasUsuarios,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
        itemsPerPageOptions: [10, 25, 50],
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
      var isSelected = ref.isSelected;
      var select = ref.select;
return [_c('tr',{key:item.nomEstruturaUsuario,class:{ 'TableRow--disabled': !item.indAtivo }},[_c('td',[_c('v-simple-checkbox',{attrs:{"value":isSelected},on:{"input":function($event){return select($event)}}})],1),_c('td',{staticClass:"justify-center px-0",staticStyle:{"width":"1%","text-align":"center"}},[(_vm.canEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar')))])]):_vm._e(),(!_vm.canEdit && _vm.canGet)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.visualizar(item)}}},on),[_c('v-icon',[_vm._v("search")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.visualizar_estrutura_usuario')))])]):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.nomEstruturaUsuario))]),_c('td',[_vm._v(_vm._s(item.nomeEstruturaUsuarioPai))]),_c('td',[_vm._v(_vm._s(item.indHierarquia ? _vm.$t('label.sim') : _vm.$t('label.nao')))]),_c('td',[_vm._v(_vm._s(item.filtroVisao))]),_c('td',[_vm._v(_vm._s(item.indRepresentante ? _vm.$t('label.sim') : _vm.$t('label.nao')))]),_c('td',[_vm._v(_vm._s(item.indRateio ? _vm.$t('label.sim') : _vm.$t('label.nao')))]),_c('td',[_vm._v(_vm._s(item.indCarteira ? _vm.$t('label.sim') : _vm.$t('label.nao')))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('confirm',{ref:"dialogConfirmaInativacao",attrs:{"message":_vm.$t('message.deseja_ativar_inativar_registros', {quantidade: _vm.selected.length}),"persistent":true},on:{"agree":_vm.ativarInativarRegistros}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }