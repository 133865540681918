<template lang="html">
  <div>
    <v-card class="card-size">
      <v-card-title>
        <h3 class="headline mb-0">{{ $tc('label.estrutura_usuario', 2) }}</h3>
      </v-card-title>
      <v-row justify="start">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="search"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3" align-self="center">
          <v-row>
            <v-tooltip bottom v-if="canEdit && selected.length">
              <template v-slot:activator="{ on }">
                <v-btn text icon class="mx-0" v-on="on" @click="dialogConfirmarInativacao()">
                  <v-icon>block</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.ativar_inativa') }}</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        v-model="selected"
        show-select
        :headers="headers"
        :items="estruturasUsuarios"
        :options.sync="pagination"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item="{ item, isSelected, select }">
          <tr :key="item.nomEstruturaUsuario" :class="{ 'TableRow--disabled': !item.indAtivo }">
            <td>
              <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
            </td>
            <td class="justify-center px-0" style="width: 1%; text-align: center;">
              <v-tooltip bottom v-if="canEdit">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click.stop="edit(item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.editar') }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!canEdit && canGet">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click.stop="visualizar(item)">
                    <v-icon>search</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.visualizar_estrutura_usuario') }}</span>
              </v-tooltip>
            </td>
            <td>{{ item.nomEstruturaUsuario }}</td>
            <td>{{ item.nomeEstruturaUsuarioPai }}</td>
            <td>{{ item.indHierarquia ? $t('label.sim') : $t('label.nao') }}</td>
            <td>{{ item.filtroVisao }}</td>
            <td>{{ item.indRepresentante ? $t('label.sim') : $t('label.nao') }}</td>
            <td>{{ item.indRateio ? $t('label.sim') : $t('label.nao') }}</td>
            <td>{{ item.indCarteira ? $t('label.sim') : $t('label.nao') }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <confirm
      ref="dialogConfirmaInativacao"
      :message="$t('message.deseja_ativar_inativar_registros', {quantidade: selected.length})"
      :persistent="true"
      @agree="ativarInativarRegistros">
    </confirm>
  </div>
</template>

<script type="text/javascript">
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  components: {
    Confirm,
  },
  data() {
    return {
      esperar: false,
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),
      estruturasUsuarios: [],
      headers: [
        { text: this.$tc('label.nome', 1), value: 'nom_estrutura_usuario' },
        { text: this.$tc('label.estrutura_pai', 2), value: 'estrutura_usuario_pai' },
        { text: this.$tc('label.estrutura_usuario', 2), value: 'ind_hierarquia' },
        { text: this.$tc('label.filtro_visao', 1), value: 'filtro_visao' },
        { text: this.$tc('label.representante', 2), value: 'ind_representante' },
        { text: this.$tc('label.ind_rateio', 2), value: 'ind_rateio' },
        { text: this.$tc('label.carteira', 1), value: 'ind_carteira' },
      ],
      pagination: {},
      totalPage: 0,
      search: '',
      selected: [],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    search() {
      this.pagination.page = 1;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  computed: {
    ...generateComputed('ESTRUTURA', [
      'canEdit',
      'canGet',
      'canSearch',
    ]),
  },
  methods: {
    ativarInativarRegistros() {
      const parametros = [];
      this.selected.forEach((item) => {
        parametros.push({ id: item.id, indAtivo: item.indAtivo });
      });

      this.estruturaUsuarioResources.ativarInativarRegistros(parametros)
        .then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.limparSelecionados();
          this.filtrar();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscar() {
      const params = {
        filter: this.search,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.estruturaUsuarioResources.listar(params)
        .then((response) => {
          this.estruturasUsuarios = this.montarRetorno(response.data.resposta);
          this.totalPage = response.data.quantidadeRegistrosPagina;
          this.limparSelecionados();
          this.pararEsperar();
        })
        .catch((err) => {
          this.pararEsperar();
          this.$error(this, err);
        });
    },
    converterBoolean(valor) {
      if (valor) return this.$t('label.sim');
      return this.$t('label.nao');
    },
    dialogConfirmarInativacao() {
      this.$refs.dialogConfirmaInativacao.open();
    },
    edit(row) {
      this.$emit('ESTRUTURAUSUARIOTABELA_EDITAR', row);
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    limparSelecionados() {
      this.selected = [];
    },
    montarRetorno(resultado) {
      resultado.forEach((linha) => {
        if (linha.estruturaUsuarioPai) {
          linha.estruturaUsuarioPai = JSON.parse(linha.estruturaUsuarioPai);
          linha.nomeEstruturaUsuarioPai = this.montarNomes(linha.estruturaUsuarioPai);
        }
      });
      return resultado;
    },
    montarNomes(estruturaUsuarioPai) {
      return estruturaUsuarioPai.map((u) => u.nomEstruturaUsuario).join(', ');
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.estruturasUsuarios.slice();
    },
    visualizar(row) {
      this.$emit('ESTRUTURAUSUARIOTABELA_VISUALIZAR', row);
    },
  },
  mounted() {
    this.buscar();
    if (this.canEdit || (!this.canEdit && this.canGet)) {
      this.headers.unshift({
        text: '', value: 'acoes', sortable: false, width: '1%', align: 'center',
      });
    }
  },
};
</script>
<style>
  .TableRow--disabled {
    color: rgba(0, 0, 0, 0.3);
  }
</style>
